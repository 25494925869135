import React, { useState } from "react"
import { Link } from "gatsby"
import { Drawer } from "@material-ui/core"
import { Container } from "./Container"
import ESLogo from "../assets/logo.svg"
import MenuIcon from "../assets/menu_icon.svg"
import ArrowIcon from "../assets/arrow_down.svg"
import CloseIcon from "../assets/close.svg"

export const Header = props => {
  const [open, setOpen] = useState(false)
  const drawerNavItemClasses = "px-10 py-3 font-sans"

  return (
    <header className="py-4 sm:py-3 bg-body-lightest sticky top-0 shadow-lg z-30">
      <Container>
        <div className="flex items-center justify-between">
          <div className="flex justify-between items-center">
            <div
              className="text-tertiary md:hidden"
              onClick={() => setOpen(true)}
            >
              <MenuIcon className="fill-current h-6 w-6 mr-2" />
            </div>
            <Link to="/" className="flex justify-between items-center">
              <ESLogo className="w-10 md:w-12" />
              <div className="flex flex-col font-bold leading-none px-2 sm:text-xl">
                <span className="text-black">Environmental</span>
                <span className="text-primary italic">Solutions</span>
              </div>
            </Link>
          </div>
          <nav className="flex justify-between">
            <Link
              className="hidden px-4 py-2 font-semibold text-sm hover:bg-black-10 hover:text-black-75 md:block"
              to="/about-us/"
            >
              About Us
            </Link>
            <div className="dropdown">
              <div className="hidden px-4 py-2 font-semibold text-sm  hover:bg-black-10 hover:text-black-75 md:block">
                Services
                <ArrowIcon className="inline-block" />
              </div>
              <div className="hidden links">
                <Link
                  className="px-4 py-2 font-semibold text-sm hover:bg-black-10 hover:text-black-75"
                  to="/water-treatment-plants/"
                >
                  Water Treatment Plants
                </Link>
                <Link
                  className="px-4 py-2 font-semibold text-sm hover:bg-black-10 hover:text-black-75"
                  to="/sewage-treatment-plants/"
                >
                  Sewage Treatment Plants
                </Link>
                <Link
                  className="px-4 py-2 font-semibold text-sm hover:bg-black-10 hover:text-black-75"
                  to="/swimming-pools/"
                >
                  Swimming Pools
                </Link>
              </div>
            </div>

            <Link
              className="hidden px-4 py-2 font-semibold mr-4 text-sm hover:bg-black-10 hover:text-black-75 md:block"
              to="/clientele/"
            >
              Clientele
            </Link>
            <Link
              className="px-2 py-2 flex-shrink-0 font-semibold text-sm cta-btn bg-secondary text-center text-white hover:bg-black-10 hover:text-black-75 sm:px-4"
              to="/contact-us/"
            >
              Contact Now
            </Link>
          </nav>
        </div>
      </Container>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        <div className="h-screen w-screen drawer-transition">
          <div
            className="mt-5 px-10 flex flex-row-reverse"
            onClick={() => setOpen(false)}
          >
            <CloseIcon className="" />
          </div>
          <div className="mt-10 px-10 text-2xl font-semibold">
            Environmental Solutions
          </div>
          <nav
            className="flex flex-col mt-8 border-t-.5 border-b-.5 border-black-25"
            onClick={() => setOpen(false)}
          >
            <Link
              className={`border-b-.5 border-black-25 ${drawerNavItemClasses}`}
              to="/about-us/"
            >
              About Us
            </Link>
            <Link
              to="/water-treatment-plants/"
              className={`border-b-.5 border-black-25 ${drawerNavItemClasses}`}
            >
              Water Treatment Plants
            </Link>
            <Link
              to="/sewage-treatment-plants/"
              className={`border-b-.5 border-black-25 ${drawerNavItemClasses}`}
            >
              Sewage Treatment Plants
            </Link>
            <Link
              to="/swimming-pools/"
              className={`border-b-.5 border-black-25 ${drawerNavItemClasses}`}
            >
              Pools &amp; Fountains
            </Link>
            <Link
              to="/clientele/"
              className={`border-b-.5 border-black-25 ${drawerNavItemClasses}`}
            >
              Clientele
            </Link>
            <Link to="/contact-us" className={`${drawerNavItemClasses}`}>
              Contact
            </Link>
          </nav>
        </div>
      </Drawer>
    </header>
  )
}
