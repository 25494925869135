import React from "react"
import { motion } from "framer-motion"

export const AnimationWrapper = props => {
  const { children, delay, animationType } = props
  let animationParams

  switch (animationType) {
    case "slideToLeft":
      animationParams = {
        initial: { x: 40, opacity: 0 },
        animate: { x: 0, opacity: 1 },
        transition: { duration: 0.6, delay: delay },
      }
      break
    case "slideToRight":
      animationParams = {
        initial: { x: -40, opacity: 0 },
        animate: { x: 0, opacity: 1 },
        transition: { duration: 0.6, delay: delay },
      }
      break
    case "slideToTop":
      animationParams = {
        initial: { y: 40, opacity: 0 },
        animate: { y: 0, opacity: 1 },
        transition: { duration: 0.6, delay: delay },
      }
      break
    case "grow":
      animationParams = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: { duration: 1, delay: delay },
      }
      break
    case "fadeIn":
      animationParams = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        transition: { duration: 1 || props.duration, delay: delay },
      }
      break
    case "fadeOut":
      animationParams = {
        initial: { opacity: 1 },
        animate: { opacity: 0.7 },
        transition: { duration: 1 || props.duration, delay: delay },
      }
      break
    default:
      break
  }

  return (
    <motion.div {...animationParams} className={props.className}>
      {children}
    </motion.div>
  )
}
