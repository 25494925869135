import React from "react"
import { Link } from "gatsby"
import { Container } from "../components/Container"
import FbIcon from "../assets/fb.svg"
import TwitterIcon from "../assets/twitter.svg"

export const Footer = () => (
  <footer className="py-4 bg-body font-light text-white tracking-wide z-20 relative">
    <Container>
      <div className="flex justify-center items-center mb-10">
        <div className="text-primary-light font-sans text-2xl font-bold -light text-center">
          Environmental Solutions
        </div>
        <div className="flex justify-around">
          <a
            className="bg-gradient-br-primary p-2 m-4 hover:p-4 hover:m-2"
            aria-label="facebook"
            href="https://www.facebook.com/Environmental-solutions-Aluva-510049809090002/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FbIcon />
          </a>
          <a
            className="bg-gradient-br-primary p-2 m-4 hover:p-4 hover:m-2"
            aria-label="twitter"
            href="/"
          >
            <TwitterIcon />
          </a>
        </div>
      </div>
      <div className="flex flex-col justify-around items-baseline  mb-10 md:flex-row">
        <div className="main-links pr-4 mb-4">
          <h1 className="font-bold text-primary-light">Main Links</h1>
          <p>
            <Link className="text-sm" to="/about-us/">
              About
            </Link>
          </p>
          <p>
            <Link className="text-sm" to="/contact-us/">
              Contact
            </Link>
          </p>
          <p>
            <Link className="text-sm" to="/water-treatment-plants/">
              Services
            </Link>
          </p>
        </div>
        <div className="sister-concerns pr-4 mb-4">
          <h1 className="font-bold text-primary-light">Our Sister Concerns</h1>
          <p className="text-sm">
            <a
              href="http://www.environmentalsystems.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Environmental Systems
            </a>
          </p>
          <p className="text-sm">Fiber Fabrica</p>
        </div>
        <div className="address mb-4">
          <h1 className="font-bold text-primary-light">Our Address</h1>
          <p className="text-sm">
            Environmental Solutions <br />
            Parambayam, NH-47, <br />
            Nedumbassery-683 585, <br />
            Aluva, Kochi,
            <br />
            Kerala, India
          </p>
        </div>
        <div className="contact-details mb-4">
          <h1 className="font-bold text-primary-light">Contact Details</h1>
          <p className="text-sm">
            Call <br />
            <a
              className="block hover:text-secondary-light"
              href="tel:+91484-2606058"
            >
              0484-2606058
            </a>
            <a
              className="block hover:text-secondary-light"
              href="tel:+919447030856"
            >
              +919447030856
            </a>
            Email <br />
            <a
              className="block hover:text-secondary-light"
              rel="noopener noreferrer"
              href="mailto:mail4cleanwater@gmail.com"
            >
              mail4cleanwater@gmail.com
            </a>
          </p>
        </div>
      </div>

      <div className="text-center text-primary-light font-light text-sm">
        <span>&copy; 2022 environmentalsolutions.co.in </span>
        <br />
        <span className="author text-xs">
          Designed & Created by{" "}
          <a
            className="hover:text-secondary-light"
            href="https://www.elizabethvincentm.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Elizabeth Vincent M
          </a>
        </span>
      </div>
    </Container>
  </footer>
)
