import React from "react"
import { AnimationWrapper } from "./AnimationWrapper"
import { checkPropTypes } from "prop-types"

export const SplitLetters = props => {
  return (
    <>
      {props.text.split("").map((letter, i) => (
        <AnimationWrapper
          key={letter + i}
          className="inline"
          animationType="fadeIn"
          delay={i * 0.05}
        >
          {letter}
        </AnimationWrapper>
      ))}
    </>
  )
}
